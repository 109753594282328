;(function(win,doc){

    var docEl = doc.documentElement,
      design = 750,
      baseSize = 50;
    var resizeEvt = "orientationchange" in win ? "orientationchange" : "resize";
    var recale = function () {
        if (doc.documentElement.clientWidth < 750) {
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        docEl.style.fontSize = baseSize * (clientWidth / design) + "px";
      }else{
        docEl.style.fontSize = "24px";
      }
    }
    if(!doc.addEventListener) return;
    win.addEventListener(resizeEvt,recale,false);
    docEl.addEventListener("DOMContentLoaded",recale,false);
    recale();
})(window,document)

