import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/article',
    name: 'ArticleDetail',
    component: () => import(/* webpackChunkName: "article" */ '@/views/times-cj/ArticleDetail__New.vue')
  },
  {
    path: '/report',
    name: 'ReportDetail',
    component: () => import(/* webpackChunkName: "report" */ '@/views/times-cj/ReportDetail.vue')
  },
  {
    path: '/flash',
    name: 'FlashDetail',
    component: () => import(/* webpackChunkName: "flash" */ '@/views/times-cj/FlashDetail.vue')
  },
  {
    path: '/video',
    name: 'VideoDetail',
    component: () => import(/* webpackChunkName: "video" */ '@/views/times-cj/VideoDetail.vue')
  },
  {
    path: '/message',
    name: 'MessageDetail',
    component: () => import(/* webpackChunkName: "message" */ '@/views/times-cj/MessageDetail.vue')
  },
  {
    path: '/article_p',
    name: 'ArticleDetail_P',
    component: () => import(/* webpackChunkName: "article_p" */ '@/views/times-cj/ArticleDetail__Publish.vue')
  },
  {
    path: '/activity-detail',
    name: 'ActivityDetail',
    component: () => import(/* webpackChunkName: "activity" */ '@/views/times-cj/activity/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
