<template>
  <div class="skeleton-container">
    <ul v-if="loading" class="skeleton-main">
      <li
        v-for="(v, i) in row"
        :key="i"
        :style="`width: ${(row < 2 || i < row - 1) ? w : lw};height: ${h};margin-bottom: ${ i < row - 1 ? spacing : 0};`"
        class="skeleton-row" />
    </ul>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Number,
      default: 2
    },
    w: {
      type: String,
      default: '100%'
    },
    lw: {
      type: String,
      default: '40%'
    },
    h: {
      type: String,
      default: '.8rem'
    },
    spacing: {
      type: String,
      default: '.5rem'
    },
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .skeleton-container {
    .skeleton-main {
      .skeleton-row {
        background-image: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
        width: 100%;
        height: 0.6rem;
        list-style: none;
        background-size: 400% 100%;
        background-position: 100% 50%;
        animation: skeleton-loading 2s ease infinite;
      }
    }
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
</style>