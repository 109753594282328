import axios from 'axios'
import qs from 'qs'
import utils from './utils'

// 创建 axios 实例
let http = axios.create({
    baseURL: utils.BASE_URL,
    // headers: {'Content-Type': 'application/json'},
    // timeout: 10000,
    cache: false
})
//设置baseURL
// 设置 post、put 默认 Content-Type
http.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
http.defaults.headers['appid'] = '44ae5dd647bc4df9a17b6d55646103b9'
// http.defaults.headers.put['Content-Type'] = 'application/json'

// 添加请求拦截器
http.interceptors.request.use(config => {
    if (config.method === 'post' || config.method === 'put') {
        if (config.headers['Content-Type'] == "application/x-www-form-urlencoded") {
            let data = config.data;
            config.data = qs.stringify(data); // 对参数进行序列化
            console.info(qs.stringify(data))
        } else if (config.headers['Content-Type'] == "application/json") {
            config.data = JSON.stringify(config.data)
        }
    } else if (config.method === 'get') {
        // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题
        config.params = {
            ...config.params,
            _t: new Date().getTime()
        }
    }

    // 请求发送前进行处理
    return config
}, error => {
    // 请求错误处理
    return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(response => {
    let { data } = response

    return data
}, error => {
    let info = {}

    if (!error.response) {
        info = {
            code: 5000,
            msg: 'Network Error'
        }

    } else {
        // 此处整理错误信息格式
        info = {
            code: "error",
            // data: data,
            msg: "请求失败"
        }
    }

    return info
})

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
    return http
}
