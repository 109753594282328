import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/api/install'

Vue.config.productionTip = false

import '@/assets/css/base.css'
import '@/assets/javascript/common.js'

import skeleton from './components/skeleton'
Vue.component('skeleton', skeleton)

Vue.prototype.$imgUrl = process.env.VUE_APP_IMGURL

Vue.use(api)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
