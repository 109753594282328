import JSSignature from 'js-signature'

export default {
    BASE_URL: `${process.env.VUE_APP_URL}`,
    initJss(method, params, uri) {
        JSSignature.init({
            key: process.env.VUE_APP_SIGNATURE_ACCESS_KEY_ID,
            secret: process.env.VUE_APP_SIGNATURE_ACCESS_KEY_SECRET,
            headerSignature: process.env.VUE_APP_SIGNATURE_HEADER_SIGNATURE,
            headerSignatureTag: process.env.VUE_APP_SIGNATURE_HEADER_SIGNATURE_TAG,
            prefix: process.env.VUE_APP_SIGNATURE_PREFIX,
            connector: process.env.VUE_APP_SIGNATURE_CONNECTOR,
            suffix: process.env.VUE_APP_SIGNATURE_SUFFIX,
            method,
            params,
            uri
        })

        return JSSignature.sign().getHeaders()
    },
    // 获取url中的参数
    getRequest(url) {
        let theRequest = new Object();
        if (url.indexOf("?") != -1) {
            let str = url.substr(1).split("?")[1],
                strs = str.split("&");
            for (let i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        return theRequest;
    },
    // 获取url跟路径
    getPathName(url) {
        const pathName = url.split("//")[0] + url.split("//")[1].split("/")[0]
            + url.split("//")[1].split("/")[1];
        return pathName;
    }
};
