import api from '../index'

export default {
  //根据预览链接获取资源详情
  getResourceDetail(params, header) {
    // return出去了一个promise
    return api.get('/admin/web/preview', params, { ...header })
  },
  getArticleInfo(params, header){
    // return出去了一个promise
    return api.get('/admin/news/article/selectDetailById', params, { ...header })
  },
  // 获取快讯详情
  getFlashInfo(params, header) {
    return api.get('/admin/news/newsflash/info', params, { ...header })
  },
  //消息详情
  getMsgInfo(params, header) {
    // return出去了一个promise
    return api.get('/admin/notice/system/info', params, { ...header })
  },
  //获取播报详情
  getBroadcastInfo(params, header){
    return api.get( '/admin/index/broadcast/info', params, { ...header })
  },
  getVideoInfo(params, header){
    // return出去了一个promise
    return api.get('/admin/video/video_ba/details', params, { ...header })
  },
}